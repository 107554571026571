input[data-switch="fasttrack"] {
    + label {
        width: 90px;

        &::before {
            right: 13px;
        }
    }

    &:checked {
        + label {
            &::before {
                left: 6px;
            }

            &::after {
                left: 68px;
            }
        }
    }
}
