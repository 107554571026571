.notes {
    max-height: 600px;
    overflow: auto;

    > * {
        &:not(:first-child) {
            display: none;
        }
    }
}

.notes-expand {
    i {
        display: inline-block;
        transform: rotate(0deg);
        transition: transform .3s;
    }

    &::after {
        content: 'Expand';
    }

    &.active {
        i {
            transform: rotate(180deg);
        }
        &::after {
            content: 'Collapse';
        }
    }
}
