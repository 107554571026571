//
// reboot.scss
//

html {
    position: relative;
    min-height: 100%;
    height: 100%;
    background-color: var(--#{$prefix}body-bg);
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}

body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 0px !important;
    position: relative;
}

%heading {
    font-weight: $font-weight-semibold;
}

button,
a {
    outline: none !important;
}

// Forms

label {
    font-weight: $font-weight-normal;
}

// Address
address.address-lg {
    line-height: 24px;
}

b,
strong {
    font-weight: $font-weight-semibold;
}

th {
    font-weight: $font-weight-semibold;
}

::selection {
  background: rgba($primary, .4); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba($primary, .4); /* Gecko Browsers */
}


// Code

pre,
code,
kbd,
samp {
    font-family: $font-family-code;
    @include font-size(1em); // Correct the odd `em` font sizing in all browsers.
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside

code {
    @include font-size($code-font-size);
    border-radius: var(--code-border-radius-sm);
    background-color: var(--code-bg);
    padding: var(--code-padding-y) var(--code-padding-x);
    font-family: var(--code-font);
    color: var(--code-color);
    word-wrap: break-word;

    // Streamline the style when inside anchors to avoid broken underline and more
    a > & {
        color: inherit;
    }
}

kbd {
    padding: $kbd-padding-y $kbd-padding-x;
    @include font-size($kbd-font-size);
    color: $kbd-color;
    background-color: $kbd-bg;
    @include border-radius($border-radius-sm);

    kbd {
        padding: 0;
        @include font-size(1em);
        font-weight: $nested-kbd-font-weight;
    }
}

pre {
    display: block;
    margin-top: 0; // 1
    margin-bottom: 1rem; // 2
    overflow: auto; // 3
    @include font-size($code-font-size);
    color: $pre-color;

    &.code-collapsed,
    &.code-expanded {
        margin-bottom: 0;
    }

    // Account for some code outputs that place code tags in pre tags
    code {
        @include font-size(inherit);
        color: inherit;
        padding: 0;
        word-break: normal;
    }

    a:not(.btn) {
        text-decoration: underline;
    }
}
