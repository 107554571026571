//
// dark-mode.scss
//

// Dark Variable
html[data-theme="dark"], [data-theme="dark"], body.dark {

  // Color system - Dark Mode only
  $gray-900: #999999;
  $gray-800: #878787;
  $gray-700: #767676;
  $gray-600: #686767;
  $gray-500: #555555;
  $gray-400: #464645;
  $gray-300: #373736;
  $gray-200: #282827;
  $gray-100: #1b1b1b;

  $grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  );

  // Prefix gray variables
  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  $white: #f3f2f0; //#b6b4b1;
  --#{$prefix}white: #{$white};

  $body-color: #a6a49f;

  // <=============================================>
  // Bootstrap variable
  // <=============================================>

  // Dark Body Background and Text Color
  --#{$prefix}body-bg: #{$gray-100};
  --#{$prefix}body-bg-rgb: #{to-rgb($gray-300)};
  --#{$prefix}body-color: #{$body-color};
  --#{$prefix}body-color-inv: #282827;

  // theme-color
  --#{$prefix}light: #{$gray-900};
  --#{$prefix}light-rgb: #{to-rgb($gray-900)};
  --#{$prefix}dark: #{$gray-100};
  --#{$prefix}dark-rgb: #{to-rgb($gray-100)};
  --#{$prefix}secondary: #{$gray-700};
  --#{$prefix}secondary-rgb: #{to-rgb($gray-700)};

  // Border variable
  --#{$prefix}border-color: #{$gray-300};

  // scss-docs-start box-shadow-variables
  --#{$prefix}box-shadow: 0px 0px 35px 0px #{rgba(0, 0, 0, 0.25)};
  --#{$prefix}box-shadow-sm: 0 .125rem .25rem #{rgba($black, .075)};
  --#{$prefix}box-shadow-lg: 0 0 45px 0 #{rgba(0, 0, 0, 0.12)};
  --#{$prefix}box-shadow-inset: inset 0 1px 2px #{rgba($black, .075)};

  // scss-docs-end box-shadow-variables
  --#{$prefix}component-active-color: #{$black};
  --#{$prefix}component-active-bg: #{$primary};

  // scss-docs-start type-variables
  --#{$prefix}text-muted: #{rgba($white, .5)};
  --#{$prefix}blockquote-footer-color: #{$gray-600};

  --#{$prefix}mark-bg: #fcf8e3;


  // scss-docs-start table-variables
  .table, .table-bordered, .gantt {
    --#{$prefix}table-header-color: inherit;
    --#{$prefix}table-striped-color: #{$gray-500};
    --#{$prefix}table-striped-bg: #{rgba($gray-100, 0.8)};
    --#{$prefix}table-active-color: #{$table-color};
    --#{$prefix}table-active-bg: #{rgba($gray-100, $table-active-bg-factor)};
    --#{$prefix}table-hover-color: #{$table-color};
    --#{$prefix}table-hover-bg-custom: #{rgba($gray-100, $table-hover-bg-factor)};
    --#{$prefix}table-hover-bg: #{$gray-100};
    --#{$prefix}table-group-separator-color: #{lighten($gray-300, 5%)};
    --#{$prefix}table-caption-color: #{$text-muted};
  }

  // scss-docs-start dropdown-variables
  .dropdown-menu, .select2-dropdown, .daterangepicker, .ql-toolbar, .tt-menu {
    --#{$prefix}dropdown-color: #{$body-color};
    --#{$prefix}dropdown-bg: #{tint-color($gray-200, 2%)};
    --#{$prefix}dropdown-border-color: #{tint-color($gray-200, 6%)};
    --#{$prefix}dropdown-divider-bg: #{tint-color($gray-300, 4%)};
    --#{$prefix}dropdown-box-shadow: #{$box-shadow};
    --#{$prefix}dropdown-link-color: #{$body-color};
    --#{$prefix}dropdown-link-hover-color: #{shade-color($gray-900, 10%)};
    --#{$prefix}dropdown-link-hover-bg: #{tint-color($gray-300, 1%)};
    --#{$prefix}dropdown-link-active-color: #{$white};
    --#{$prefix}dropdown-link-active-bg: #{tint-color($gray-300, 2%)};
    --#{$prefix}dropdown-link-disabled-color: #{$gray-600};
  }

  // scss-docs-start dropdown-dark-variables
  .dropdown-menu-dark, .select2-dropdown, .daterangepicker, .ql-toolbar {
    --#{$prefix}dropdown-dark-color: #{$gray-300};
    --#{$prefix}dropdown-dark-bg: #{$gray-800};
    --#{$prefix}dropdown-dark-border-color: #{$dropdown-border-color};
    --#{$prefix}dropdown-dark-divider-bg: #{$dropdown-divider-bg};
    --#{$prefix}dropdown-dark-link-color: #{$dropdown-dark-color};
    --#{$prefix}dropdown-dark-link-hover-color: #{$white};
    --#{$prefix}dropdown-dark-link-hover-bg: #{rgba($white, .15)};
    --#{$prefix}dropdown-dark-link-active-color: #{$dropdown-link-active-color};
    --#{$prefix}dropdown-dark-link-active-bg: #{$dropdown-link-active-bg};
    --#{$prefix}dropdown-dark-link-disabled-color: #{$gray-500};
    --#{$prefix}dropdown-dark-header-color: #{$gray-500};
  }


  // scss-docs-start nav-variables
  .nav {
    --#{$prefix}nav-link-disabled-color: #{$gray-600};
    --#{$prefix}nav-tabs-border-color: #{$gray-300};
    --#{$prefix}nav-tabs-link-hover-border-color: #{$gray-200} #{$gray-200} #{$nav-tabs-border-color};
    --#{$prefix}nav-tabs-link-active-color: #{$gray-800};
    --#{$prefix}nav-tabs-link-active-bg: #{tint-color($gray-100, 5%)};
    --#{$prefix}nav-tabs-link-active-border-color: #{$gray-300 $gray-300 $nav-tabs-link-active-bg};
  }

  --#{$prefix}navbar-box-shadow: none;
  --#{$prefix}navbar-outline: 2px solid #{$gray-100};

  // scss-docs-start navbar-theme-variables
  .navbar {
    --#{$prefix}navbar-light-color: #{shade-color($gray-700,5%)};
    --#{$prefix}navbar-light-hover-color: #{$gray-800};
    --#{$prefix}navbar-light-active-color: #{rgba($white, .9)};
    --#{$prefix}navbar-light-disabled-color: #{$gray-500};
  }

  // scss-docs-start navbar-dark-variables
  .navbar-dark {
    --#{$prefix}navbar-dark-color: #{rgba($white, .55)};
    --#{$prefix}navbar-dark-hover-color: #{rgba($white, .75)};
    --#{$prefix}navbar-dark-active-color: #{$white};
    --#{$prefix}navbar-dark-disabled-color: #{rgba($white, .25)};
    --#{$prefix}navbar-dark-toggler-border-color: #{rgba($white, .1)};
  }

  .card, .card-header {
    // scss-docs-start card-variables
    --#{$prefix}card-border-color: #{rgba($black, .125)};
    --#{$prefix}card-box-shadow: #{$box-shadow};
    --#{$prefix}card-cap-bg: #{$gray-200};
    --#{$prefix}card-bg: #{$black};
  }


  // scss-docs-start accordion-variables
  .accordion {
    --#{$prefix}accordion-color: #{var(--#{$prefix}body-color)};
    --#{$prefix}accordion-border-color: #{var(--#{$prefix}border-color)};
    --#{$prefix}accordion-button-active-bg: #{rgba($primary, 0.05)};
    --#{$prefix}accordion-button-active-color: #{shade-color($primary, 10%)};
    --#{$prefix}accordion-button-focus-border-color: #{$input-focus-border-color};
    --#{$prefix}accordion-button-focus-box-shadow: #{inset 0 ($accordion-border-width * -1) 0 $accordion-border-color$btn-focus-box-shadow};
  }

  // scss-docs-start pagination-variables
  .pagination {
    --#{$prefix}pagination-color: #{$white};
    --#{$prefix}pagination-bg: transparent; //#{tint-color($gray-200, 4%)};
    --#{$prefix}pagination-border-color: #{$gray-300};
    --#{$prefix}pagination-focus-color: #{$link-hover-color};
    --#{$prefix}pagination-focus-bg: transparent; //#{$gray-200};
    --#{$prefix}pagination-focus-box-shadow: #{$input-btn-focus-box-shadow};
    --#{$prefix}pagination-hover-color: #{$gray-900};
    --#{$prefix}pagination-hover-bg: #{tint-color($gray-200, 8%)};
    --#{$prefix}pagination-hover-border-color: #{$gray-300};
    --#{$prefix}pagination-disabled-color: #{$gray-400};
    --#{$prefix}pagination-disabled-bg: transparent; //#{lighten(tint-color($gray-200,4%),5%)};
    --#{$prefix}pagination-disabled-border-color: #{lighten(tint-color($gray-200,4%),5%)};
  }


  // scss-docs-start progress-variables
  .progress, .irs, .gantt {
    --#{$prefix}progress-bg: #{$gray-300};
    --#{$prefix}progress-box-shadow: #{$box-shadow-inset};
    --#{$prefix}progress-bar-color: #{$white};
    --#{$prefix}progress-bar-bg: #{$primary};
  }

  // scss-docs-start list-group-variables
  .list-group {
    --#{$prefix}list-group-color: #{$gray-900};
    --#{$prefix}list-group-bg: #{$card-bg};
    --#{$prefix}list-group-border-color: #{tint-color($gray-300, 4%)};
    --#{$prefix}list-group-hover-bg: #{$gray-100};
    --#{$prefix}list-group-disabled-color: #{$gray-400};
    --#{$prefix}list-group-disabled-bg: #{$gray-100};
    --#{$prefix}list-group-action-color: #{$gray-500};
    --#{$prefix}list-group-action-active-color: #{$gray-700};
    --#{$prefix}list-group-action-active-bg: #{$gray-100};
  }


  // scss-docs-start toast-variables
  .toast {
    --#{$prefix}toast-background-color: #{$gray-100};
    --#{$prefix}toast-border-color: #{rgba($gray-900, .12)};
    --#{$prefix}toast-header-background-color: #{rgba($gray-100, .2)};
    --#{$prefix}toast-header-border-color: #{rgba($gray-900, .05)};
  }

  // scss-docs-start modal-variables
  .modal, .modal-backdrop, .offcanvas, .offcanvas-backdrop {
    --#{$prefix}modal-content-bg: #{tint-color($gray-200, 2%)};
    --#{$prefix}modal-content-box-shadow-xs: #{$box-shadow-sm};
    --#{$prefix}modal-content-box-shadow-sm-up: #{$box-shadow};
    --#{$prefix}modal-backdrop-bg: #{$gray-500};
    --#{$prefix}modal-header-border-color: #{lighten($gray-300,5%)};
    --#{$prefix}modal-footer-border-color: #{lighten($gray-300,5%)};
  }

  // scss-docs-start offcanvas-variables
  .offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
    --#{$prefix}offcanvas-bg: #{tint-color($gray-200, 2%)};
    --#{$prefix}offcanvas-border-color: #{transparent};
  }


  // scss-docs-start tooltip-variables
  .tooltip {
    --#{$prefix}tooltip-color: #{$black};
    --#{$prefix}tooltip-bg: #{$white};
  }

  // scss-docs-start popover-variables
  .popover {
    --#{$prefix}popover-bg: #{$gray-200};
    --#{$prefix}popover-border-color: #{$gray-300};
    --#{$prefix}popover-header-bg: #{lighten($gray-200, 2.5%)};
    --#{$prefix}popover-header-color: #{$gray-700};
    --#{$prefix}popover-body-color: #{$gray-700};
    --#{$prefix}popover-arrow-color: #{$gray-200};
    --#{$prefix}popover-arrow-outer-color: #{$gray-300};
  }

  // scss-docs-start form-text-variables
  --#{$prefix}form-text-color: #{$text-muted};

  // form search input
  --#{$prefix}search-bg: #{$gray-300};
  --#{$prefix}search-border-color: #{$gray-100};
  --#{$prefix}search-color: #{$white};

  // scss-docs-start form-input-variables
  --#{$prefix}input-bg: #{$black};
  --#{$prefix}input-disabled-bg: #{$black};
  --#{$prefix}input-color: #{$white};
  --#{$prefix}input-border-color: #{darken($white, 30%)};
  --#{$prefix}input-box-shadow: #{$box-shadow-inset};
  --#{$prefix}input-focus-bg: #{$black};
  --#{$prefix}input-focus-border-color: #{$white};
  --#{$prefix}input-focus-color: #{$input-color};
  --#{$prefix}input-placeholder-color: #{$gray-600};
  --#{$prefix}input-plaintext-color: #{$white};

  // scss-docs-start form-check-variables
  --#{$prefix}form-check-input-bg: transparent;
  --#{$prefix}form-check-input-border: #{$border-width} solid #{$gray-400};
  --#{$prefix}form-check-input-checked-color: #{$component-active-color};
  --#{$prefix}form-check-input-checked-bg-color: #{$component-active-bg};
  --#{$prefix}form-check-input-checked-border-color: #{$form-check-input-checked-bg-color};
  --#{$prefix}form-check-input-indeterminate-color: #{$component-active-color};
  --#{$prefix}form-check-input-indeterminate-bg-color: #{$component-active-bg};
  --#{$prefix}form-check-input-indeterminate-border-color: #{$form-check-input-indeterminate-bg-color};

  // scss-docs-start form-switch-variables
  --#{$prefix}form-switch-color: #{tiny-color($gray-300, 6%)};
  --#{$prefix}form-switch-bg: #{$gray-300};

  // scss-docs-start input-group-variables
  --#{$prefix}input-group-addon-color: #{$gray-100};
  --#{$prefix}input-group-addon-bg: #{tint-color($gray-300, 1%)};
  --#{$prefix}input-group-addon-border-color: #{$input-border-color};

  // scss-docs-start form-select-variables
  --#{$prefix}form-select-color: #{$input-color};
  --#{$prefix}form-select-bg: #{$input-bg};
  --#{$prefix}form-select-disabled-color: #{$gray-600};
  --#{$prefix}form-select-disabled-bg: #{$gray-200};
  --#{$prefix}form-select-disabled-border-color: #{$input-disabled-border-color};
  --#{$prefix}form-select-indicator-color: #{$body-color};

  // scss-docs-start form-range-variables
  --#{$prefix}form-range-track-bg: #{$gray-300};
  --#{$prefix}form-range-track-box-shadow: inset 0 .25rem .25rem #{rgba($black, .1)};
  --#{$prefix}form-range-thumb-box-shadow: 0 .1rem .25rem #{rgba($black, .1)};
  --#{$prefix}form-range-thumb-active-bg: #{tint-color($primary, 70%)};
  --#{$prefix}form-range-thumb-disabled-bg: #{$gray-500};

  // scss-docs-start form-file-variables
  --#{$prefix}form-file-button-color: #{$input-color};
  --#{$prefix}form-file-button-bg: #{tint-color($gray-300, 1%)};
  --#{$prefix}form-file-button-hover-bg: #{shade-color($gray-300, 5%)};

  // scss-docs-start thumbnail-variables
  --#{$prefix}thumbnail-bg: #{$gray-300};
  --#{$prefix}thumbnail-border-color: #{$gray-300};
  --#{$prefix}thumbnail-box-shadow: #{$box-shadow-sm};

  // scss-docs-start close-variables
  //--#{$prefix}btn-close-color: #{$gray-800};
  --#{$prefix}btn-close-icon: #{url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='" + rgba($body-color,.99) + "'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")};

  --#{$prefix}kbd-color: #{$white};
  --#{$prefix}kbd-bg: #{$gray-900};
  --#{$prefix}pre-color: #{$gray-500};


  // <=============================================>
  // Custome variable
  // <=============================================>

  // Left Sidebar
  --#{$prefix}bg-leftbar: #{$black};

  // Menu
  --#{$prefix}dark-menu-item: currentColor;
  --#{$prefix}dark-menu-item-hover: #{$primary-alt};
  --#{$prefix}dark-menu-item-active: #{$white};
  --#{$prefix}dark-menu-item-active-bg: rgba(255, 255, 255, .07);

  // Topbar
  --#{$prefix}bg-topbar: #{$gray-200};
  --#{$prefix}bg-topbar-search: #{$black};
  --#{$prefix}nav-user-bg-topbar: #{lighten($gray-100, 2.5%)};
  --#{$prefix}nav-user-border-topbar: #{$gray-100};
  --#{$prefix}notification-border-color: #{lighten($gray-100, 2.5%)};
  --#{$prefix}notification-bg: #{lighten($gray-100, 2.5%)};

  // Dark Topbar
  --#{$prefix}bg-dark-topbar: #86cdb8;
  --#{$prefix}bg-dark-topbar-search: rgba(255, 255, 255, 0.1);
  --#{$prefix}nav-user-bg-dark-topbar: rgba(255, 255, 255, 0.1);
  --#{$prefix}nav-user-border-dark-topbar: rgba(255, 255, 255, 0.15);


  // Background left-sidebar
  --#{$prefix}bg-leftbar-gradient: #{$black};
  --#{$prefix}bg-topnav: #{linear-gradient(to bottom, #86cdb8, #86cdb8)};

  // Boxed Layout
  --#{$prefix}boxed-layout-bg: #3e4853;

  // Helpbox
  --#{$prefix}help-box-light-bg: #{rgba($white, 0.1)};
  --#{$prefix}help-box-dark-bg: #{$primary};

  // Nav-pill background
  --#{$prefix}nav-pills-bg: #404954;

  // Custom-accordion
  --#{$prefix}custom-accordion-title-color: #{$gray-400};

  // Dragula demo background
  --#{$prefix}dragula-bg: #404954;

  // Form wizard header background
  --#{$prefix}form-wizard-header-bg: #404954;

  // Text title color
  --#{$prefix}text-title-color: #{$white};

  // Page title color
  --#{$prefix}page-title-color: #{$white};


  // card Loader background
  --#{$prefix}card-loader-bg: #{$gray-900};

  // Chat widget
  --#{$prefix}chat-primary-user-bg: #404954;
  --#{$prefix}chat-secondary-user-bg: #404954;

  // User authentication Background
  --#{$prefix}auth-bg: var(--#{$prefix}gray-200);

  // Apex chart
  --#{$prefix}apex-grid-color: #404954;

  // Hero
  --#{$prefix}hero-bg: #{linear-gradient(to bottom, #697ded, #5e30c1)};

  --#{$prefix}display-dark: none;
  --#{$prefix}display-light: block;

  // Calendar
  --#{$prefix}calendar-event-color: #{$gray-100};


  &[data-sidenav-mode="dark"] {
    // Menu
    --#{$prefix}menu-item: #8391a2;
    --#{$prefix}menu-item-hover: #{$primary};
    --#{$prefix}menu-item-active: #{$primary-alt};
  }

  &[data-sidenav-color="default"] {
    // Left Sidebar
    --#{$prefix}bg-leftbar: #{$primary};
    --#{$prefix}bg-leftbar-gradient: #{$primary};
    // Menu
    --#{$prefix}menu-item: #{$black};
    --#{$prefix}menu-item-hover: #{$offwhite};
    --#{$prefix}menu-item-active: #{$black};
    --#{$prefix}menu-item-active-bg: #{rgba($offwhite, .5)};
  }

  &[data-sidenav-color="light"] {
    // Left Sidebar
    --#{$prefix}bg-leftbar: #{$white};

    // Menu
    --#{$prefix}menu-item: #{$black};
    --#{$prefix}menu-item-hover: #{$primary-alt};
    --#{$prefix}menu-item-active: #{$black};
  }

  &[data-layout-mode="detached"]:not([data-layout="topnav"]) {
    --#{$prefix}bg-topbar: #{$black};
  }

  // .btn[class*="btn-outline-"] {
  //     color: var(--#{$prefix}body-color);
  // }

  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$body-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }

  .conversation-list {
    .ctext-wrap {
      background-color: var(--#{$prefix}black);
      color: var(--#{$prefix}white);
    }
  }
}

