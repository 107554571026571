/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

:root {
    --code-border-radius: var(--#{$prefix}border-radius);
    --code-border-radius-sm: var(--#{$prefix}border-radius-sm);
    --code-bg: var(--#{$prefix}body-bg);
    --code-font: var(--#{$prefix}font-monospace);
    --code-color: inherit;
    --code-text-shadow: none;
    --code-color-1: slategray;
    --code-color-2: #999;
    --code-color-3: #905;
    --code-color-4: #690;
    --code-color-5: #9a6e3a;
    --code-color-6: #07a;
    --code-color-7: #dd4a68;
    --code-color-8: #e90;
}

/* Code blocks */
/* Inline code */
code[class*="language-"] {
    color: var(--code-color);
    background: none;
    text-shadow: var(--code-text-shadow);
    font-family: var(--code-font);
    font-size: var(--#{$prefix}body-font-size);
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;

    &::selection {
        text-shadow: none;
        background: #b3d4fc;
    }
}

pre[class*="language-"] {
    color: var(--code-color);
    border-radius: var(--code-border-radius);
    background: none;
    text-shadow: var(--code-text-shadow);
    font-family: var(--code-font);
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
    padding: 1em 1.3em;
    margin: .5em 0;
    overflow: auto;
    background: var(--code-bg);

    &::selection {
        text-shadow: none;
        background: #b3d4fc;
    }

    &.code-collapsed,
    &.code-expanded {
        margin-bottom: 0;
        border-radius: var(--code-border-radius) var(--code-border-radius) 0 0;
    }
}

:not(pre) {
    > code[class*="language-"] {
        background: var(--code-bg);
        padding: .1em;
        border-radius: .3em;
        white-space: normal;
    }
}

.token {
    &.comment {
        color: var(--code-color-1);
    }

    &.prolog {
        color: var(--code-color-1);
    }

    &.doctype {
        color: var(--code-color-1);
    }

    &.cdata {
        color: var(--code-color-1);
    }

    &.punctuation {
        color: var(--code-color-2);
    }

    &.namespace {
        opacity: .7;
    }

    &.property {
        color: var(--code-color-3);
    }

    &.tag {
        color: var(--code-color-3);
    }

    &.boolean {
        color: var(--code-color-3);
    }

    &.number {
        color: var(--code-color-3);
    }

    &.constant {
        color: var(--code-color-3);
    }

    &.symbol {
        color: var(--code-color-3);
    }

    &.deleted {
        color: var(--code-color-3);
    }

    &.selector {
        color: var(--code-color-4);
    }

    &.attr-name {
        color: var(--code-color-4);
    }

    &.string {
        color: var(--code-color-4);
    }

    &.char {
        color: var(--code-color-4);
    }

    &.builtin {
        color: var(--code-color-4);
    }

    &.inserted {
        color: var(--code-color-4);
    }

    &.operator {
        color: var(--code-color-5);
        background: hsla(0, 0%, 100%, .5);
    }

    &.entity {
        color: var(--code-color-5);
        background: hsla(0, 0%, 100%, .5);
        cursor: help;
    }

    &.url {
        color: var(--code-color-5);
        background: hsla(0, 0%, 100%, .5);
    }

    &.atrule {
        color: var(--code-color-6);
    }

    &.attr-value {
        color: var(--code-color-6);
    }

    &.keyword {
        color: var(--code-color-6);
    }

    &.function {
        color: var(--code-color-7);
    }

    &.class-name {
        color: var(--code-color-7);
    }

    &.regex {
        color: var(--code-color-8);
    }

    &.important {
        color: var(--code-color-8);
        font-weight: bold;
    }

    &.variable {
        color: var(--code-color-8);
    }

    &.bold {
        font-weight: bold;
    }

    &.italic {
        font-style: italic;
    }
}

.language-css {
    .token.string {
        color: var(--code-color-5);
        background: hsla(0, 0%, 100%, .5);
    }
}

.style {
    .token.string {
        color: var(--code-color-5);
        background: hsla(0, 0%, 100%, .5);
    }
}

@media print {
    code[class*="language-"] {
        text-shadow: none;
    }
    pre[class*="language-"] {
        text-shadow: none;
    }
}
