//
// Fabriga fonts
//

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fabriga';
  src: url('https://cdn.jonahdigital.com/ds/fonts/Fabriga-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
