body {
    .tox {
        @include border-radius($input-border-radius, 0);
        color: var(--#{$prefix}body-color);
        font-family: var(--#{$prefix}body-font-family);

        &.tox {
            // .tox-tinymce
            &-tinymce {
                border: 1px solid var(--#{$prefix}input-border-color);
            }

            // .tox:not(.tox-tinymce-inline) .tox-editor-header
            &:not(.tox-tinymce-inline) {
                .tox-editor-header {
                    box-shadow: none;
                    border-bottom: 1px solid var(--#{$prefix}input-border-color);
                    background-color: var(--#{$prefix}input-bg);
                    padding: 0;
                }
            }

            .tox-menubar,
            .tox-toolbar-overlord,
            .tox-toolbar,
            .tox-toolbar__overflow,
            .tox-toolbar__primary,
            .tox-edit-area__iframe,
            .tox-statusbar {
                border-color: var(--#{$prefix}input-border-color);
                background-color: var(--#{$prefix}input-bg);
                color: inherit;
            }

            .tox-mbtn {
                color: inherit;

                &--active {
                    background-color: var(--#{$prefix}primary);
                    color: var(--#{$prefix}dark);
                }

                &:focus:not(:disabled),
                &:hover:not(:disabled):not(.tox-mbtn--active) {
                    background-color: var(--#{$prefix}input-border-color);
                    color: var(--#{$prefix}dark);
                }
            }

            .tox-tbtn {
                color: inherit;

                &:focus,
                &:hover {
                    background-color: var(--#{$prefix}input-border-color);
                    color: var(--#{$prefix}dark);
                }

                svg {
                    fill: currentColor;
                }

                &--bespoke {
                    background-color: var(--#{$prefix}input-bg);
                }

                &--enabled,
                &--enabled:hover {
                    background-color: var(--#{$prefix}primary);
                    color: var(--#{$prefix}dark);
                }

                &--disabled,
                &--disabled:hover,
                &:disabled,
                &:disabled:hover {
                    background-color: transparent;
                    opacity: 0.5;
                    color: var(--#{$prefix}body-color);
                }
            }

            .tox-split-button {
                &:hover {
                    box-shadow: 0 0 0 1px var(--#{$prefix}input-border-color) inset;
                }
            }

            .tox-menu {
                background-color: var(--#{$prefix}body-bg);
                color: var(--#{$prefix}body-color);
            }

            .tox-collection--list {
                .tox-collection__group {
                    border-color: var(--#{$prefix}input-border-color);
                }

                .tox-collection__item {
                    color: var(--#{$prefix}body-color);

                    &-accessory {
                        color: inherit;
                    }

                    &--active {
                        background-color: var(--#{$prefix}input-border-color);
                        color: var(--#{$prefix}dark);

                        &:not(.tox-collection__item--state-disabled) {
                            color: var(--#{$prefix}dark);
                        }
                    }

                    &--enabled {
                        background-color: var(--#{$prefix}primary);
                        color: var(--#{$prefix}dark);
                    }
                }
            }

            .tox-collection--toolbar {
                .tox-collection__item {
                    &--active {
                        background-color: var(--#{$prefix}input-border-color);
                        color: var(--#{$prefix}dark);
                    }
                }
            }

            .tox-statusbar {
                a,
                &__path-item,
                &__wordcount {
                    color: inherit;
                }

                &__branding {
                    display: none;

                    svg {
                        fill: currentColor;
                    }
                }
            }
        }


        .tox-toolbar,
        .tox-toolbar__overflow,
        .tox-toolbar__primary {
            background-image: repeating-linear-gradient(var(--#{$prefix}input-border-color) 0 1px, transparent 1px 39px);
        }

        .tox-statusbar a:focus:not(:disabled):not([aria-disabled=true]),
        .tox-statusbar a:hover:not(:disabled):not([aria-disabled=true]),
        .tox-statusbar__path-item:focus:not(:disabled):not([aria-disabled=true]),
        .tox-statusbar__path-item:hover:not(:disabled):not([aria-disabled=true]),
        .tox-statusbar__wordcount:focus:not(:disabled):not([aria-disabled=true]),
        .tox-statusbar__wordcount:hover:not(:disabled):not([aria-disabled=true]) {
            color: var(--#{$prefix}primary);
        }

        &.tox-tinymce-aux {
            .tox-toolbar__overflow {
                background-color: var(--#{$prefix}body-bg);
                color: var(--#{$prefix}body-color);
            }
        }
    }
}
