.wysiwyg-output {
    h1 {
        @extend .h1;
    }

    h2 {
        @extend .h2;
    }

    h3 {
        @extend .h3;
    }

    h4 {
        @extend .h4;
    }

    h5 {
        @extend .h5;
    }

    h6 {
        @extend .h6;
    }


    p {}

    a {
        &:not(.btn) {
            color: var(--#{$prefix}primary-alt);
            text-decoration: none;
        }
    }

    strong {}
    em {}

    sub {}
    sup {}

    ul {}
    ol {}
    li {}

    table {
        @extend .table, .table-sm, .table-bordered;
        white-space: normal;
    }

    blockquote {
        @extend .blockquote;

        p {}

        cite {
            display: block;
            margin-top: -1em;
            font-size: .75rem;
            color: var(--#{$prefix}blockquote-footer-color);
        }
    }
}
