//
// authentication.scss
//

// authentication pages background
body.authentication-bg {
    height: 100%;

    .account-pages {
        align-items: center;
        display: flex;
        min-height: 100vh;
    }
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-sidenav-size="condensed"] {
    min-height: 100px;
}

// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid
.auth-fluid {
    position: relative;
    display: flex;
    min-height: 100%;
    flex-direction: row;
    background-size: cover;
    background-color: var(--#{$prefix}auth-bg);

    .auth-fluid-form-box {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        min-width: 500px;
        width: 40%;
        border-radius: 0;
        background: var(--#{$prefix}body-bg);
        padding: $grid-gutter-width;
        transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);

        > * {
            margin: auto;
            width: 360px;
        }
    }

    .auth-fluid-left {
        padding: 0;
        flex: 1;
        position: relative;
    }

    .auth-fluid-right {
        min-width: 500px;
        width: 40%;
    }

    .footer {
        position: relative;
        bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        width: 100%;

        p {
            margin-bottom: 0;
        }
    }
}

.auth-brand {
    margin-bottom: 0;

    .logo-dark {
        display: $logo-auth-dark-display;
    }

    .logo-light {
        display: $logo-auth-light-display;
    }
}

.auth-brand-logo {
    display: inline-block;
    width: 100px;

    svg {
        height: auto;
        vertical-align: middle;
    }
}

.auth-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50%;
    height: 50%;
    transition: all .5s;
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;

    p.lead {
        font-size: 1.125rem;
        margin: 0 auto 20px auto;
        max-width: 700px;
    }
}

@include media-breakpoint-up(lg) {
    .auth-brand {
        position: absolute;
        top: 3rem;
    }
}

@include media-breakpoint-down(lg) {
    .auth-fluid {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .auth-fluid-left {
            width: 100%;
            min-height: 200px;
        }

        .auth-logo {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
        }

        .auth-fluid-form-box {
            position: relative;
            top: auto;
            bottom: auto;
            // flex: 1;
            display: flex;
            flex-direction: column;
            min-width: 0;
            width: 100%;

            > * {
                flex: 1;
                width: 100%;
            }
        }

        .auth-fluid-right {
            display: none;
        }
    }
}

// Dark mode
html[data-theme="dark"] {
    .auth-brand {
        .logo-dark {
            display: $logo-auth-light-display;
        }
        .logo-light {
            display: $logo-auth-dark-display;
        }
    }
}
