// ----------------------------------------------------------------------------
//  App List
// ----------------------------------------------------------------------------
.app-list {
    max-height: 275px;
    overflow: auto;
}


// ----------------------------------------------------------------------------
//  Random
// ----------------------------------------------------------------------------
html {
    .tox {
        .tox-dialog__iframe.tox-dialog__iframe--opaque,
        .tox-dialog__header,
        .tox-dialog__footer {
            background-color: #fff;
            color: inherit;
        }

        .tox-dialog__header {
            border-bottom: 1px solid var(--#{$prefix}border-color);
            padding-bottom: 8px;
        }

        .tox-selectfield select {
            border-color: var(--#{$prefix}input-border-color);
            background-color: var(--#{$prefix}form-select-bg);
            color: var(--#{$prefix}form-select-color);

            &:focus {
                box-shadow: none;
                border-color: inherit;
                background-color: inherit;
            }
        }

        .tox-dialog__body,
        .tox-label,
        .tox-toolbar-label {
            color: inherit;
        }

        .tox-button {
            @extend .btn, .btn-primary;

            &:hover:not(:disabled),
            &:focus:not(:disabled) {
                background-color: inherit;
                background-image: none;
                border-color: inherit;
                box-shadow: none;
                color: inherit;
            }
        }
    }

    .tox-button--secondary {
        color: #282827 !important;
        background-color: #dce2e0 !important;
        border: #dce2e0 !important;
        border-radius:1px  !important;
    }

    .standards {
        background-color: #fbfaf8;
        border: solid 1px #d6d5d3;
        border-radius: 3px;
    }

    .bg-domain {
        font-size: 8px;
        padding: 8px 10px 8px 10px;
        margin-bottom: 2px;
    }

    &[data-theme="dark"] {
        .tox {
            .tox-dialog {
                background-color: var(--#{$prefix}body-bg);
            }

            .tox-dialog-wrap__backdrop {
                background-color: rgba(0,0,0,.7);
            }

            .tox-dialog__iframe.tox-dialog__iframe--opaque,
            .tox-dialog__header,
            .tox-dialog__footer {
                background-color: transparent;
                color: inherit;
            }
        }
    }

    .has-clipboard-button {
        position: relative;
    }

    .clipboard-copy-button {
        position: absolute;
        top: 2px;
        right: 2px;
        appearance: none;
        border-radius: 4px;
        border: 0;
        background: var(--#{$prefix}gray-100);
        box-shadow: none;
        color: var(--#{$prefix}gray-500);
        font-size: .8em;
        padding: 0 0.5em;
        line-height: 1.2;
    }
}
