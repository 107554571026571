//
// apexcharts.scss
//

.apex-charts {
    min-height: 10px !important;

    text {
        font-family: $font-family-base  !important;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base  !important;
}

.apexcharts-canvas {
  .apexcharts-tooltip-series-group {
    padding-bottom: 0 !important;
  }

  .apexcharts-tooltip-y-group {
    padding: 4px 0;
  }
}

.apexcharts-tooltip {
    border: 1px solid var(--#{$prefix}gray-300) !important;
    background-color: $card-bg  !important;
    box-shadow: $box-shadow  !important;

    * {
        font-family: $font-family-base  !important;
        color: var(--#{$prefix}gray-600) !important;
    }

    .apexcharts-tooltip-title {
        background-color: rgba(var(--#{$prefix}light-rgb), 0.75) !important;
        border-bottom: 1px solid var(--#{$prefix}gray-300) !important;
    }
}

.apexcharts-tooltip.apexcharts-theme-dark {
    * {
        color: $white  !important;
    }
}

.apexcharts-legend-series {
    font-weight: $font-weight-semibold;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: var(--#{$prefix}gray-600) !important;
    font-family: $font-family-base  !important;
    font-size: 13px !important;
    vertical-align: middle;
}

.apexcharts-legend-marker {
    vertical-align: middle;
    margin-right: 5px !important;
}

.apexcharts-pie-label {
    fill: $white  !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
    font-family: $font-family-base  !important;
    font-weight: $font-weight-semibold !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base  !important;
        fill: var(--#{$prefix}gray-500);
    }
}

.apexcharts-yaxis-title {
    font-weight: 500;
}

.apexcharts-annotation-rect {
    fill: var(--#{$prefix}gray-100) !important;
}

.apexcharts-xaxis-annotations,
.apexcharts-yaxis-annotations {
    rect {
        stroke: none;
    }
}

.apex-toolbar {
    .btn-light.active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        box-shadow: $btn-box-shadow rgba($primary, 0.5);
    }
}

.apexcharts-xaxistooltip {
    background-color: $dropdown-bg  !important;
    border: $dropdown-border-width solid $dropdown-border-color  !important;
    box-shadow: $box-shadow  !important;
    color: $dropdown-link-color  !important;
}

.apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: $dropdown-border-color  !important;
}

.apexcharts-xaxistooltip-text,
.apexcharts-datalabels text {
    font-family: $font-family-base  !important;
}

.apexcharts-datalabel-value {
    fill: $dropdown-link-color  !important;
}

.apexcharts-menu {
    background: $dropdown-bg  !important;
    padding: $dropdown-padding-y $dropdown-padding-x  !important;
    box-shadow: $box-shadow  !important;
    border: $dropdown-border-width solid $dropdown-border-color  !important;
    text-align: center !important;

    .apexcharts-menu-item {
        &:hover {
            color: $dropdown-link-hover-color  !important;
            background-color: $dropdown-link-hover-bg  !important;
        }
    }
}


.apexcharts-radar-series {
    polygon {
        fill: transparent;
        stroke: var(--#{$prefix}gray-300);
    }

    line {
        stroke: var(--#{$prefix}gray-300);
    }
}


.apexcharts-track {
    path {
        stroke: var(--#{$prefix}gray-300);
    }
}
