//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 50px !important;
        margin: 0 3px !important;
        border: none;
        line-height: 1;
    }
}
