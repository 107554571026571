//
// alert.scss
//
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        .btn-close {
            @if $color == 'secondary' or $color == 'light' {
                background-image: escape-svg(#{url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='" + shift-color($value, 30%) + "'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")});
            }
            @else {
                background-image: escape-svg(#{url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='" + $value + "'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")});
            }
        }

        .alert-link {
            color: inherit;
            text-decoration: underline;
        }
    }

    html[data-theme="dark"] {
        .alert-#{$color} {
            @if $color == 'dark' {
                color: shift-color($value, -60%);
                border-color: shift-color($value, -60%);
            }
            @else {
                color: shift-color($value, -10%);
                border-color: shift-color($value, -10%);
            }
            background-color: shift-color($value, 80%);

            .btn-close {
                @if $color == 'secondary' or $color == 'light' {
                    background-image: escape-svg(#{url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='" + shift-color($value, 30%) + "'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")});
                }
                @else if $color == 'dark' {
                    background-image: escape-svg(#{url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='" + shift-color($value, -30%) + "'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")});
                }
            }
        }
    }
}
