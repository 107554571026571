.table-filters {
    .form-select {
        text-overflow: ellipsis;

        @include media-breakpoint-up(xxl) {
            max-width: 135px;
        }
    }
}

div.dataTables_wrapper {
    overflow: auto;
}

div.dataTables_info {
    float: left;
    width: 40%;
    padding-top: .6em !important;
}

.dataTables_paginate {
    float:right;
    width: 40%;
}

// Tables in cards
.card-body {
    &.ps-0,
    &.p-0 {
        .table {
            th:first-child,
            td:first-child {
                padding-left: var(--#{$prefix}card-spacer-x);
            }
        }
    }
    &.pe-0,
    &.p-0 {
        .table {
            th:last-child,
            td:last-child {
                padding-right: var(--#{$prefix}card-spacer-x);
            }
        }
    }
}
