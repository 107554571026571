//
// text.scss
//

@each $key, $value in $grays2 {
  .text-#{$key} {
    color: var(--#{$prefix}#{$key});
  }
}

@each $color, $value in $theme-colors-rgb {
  .text-#{$color}-lighten {
    --#{$prefix}text-opacity: 0.25;
    color: rgba(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}text-opacity)) !important;
  }
}

// Dark Mode

html[data-theme="dark"] {
    .text-body {
        color: var(--#{$prefix}body-color) !important;
    }

    .text-light {
        color: var(--#{$prefix}body-color) !important;
    }

    .text-secondary {
        color: var(--#{$prefix}gray-700) !important;
    }
}
