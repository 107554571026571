// Utilities

@import "utilities/background";
@import "utilities/text";

// border style
.border-dashed {
    border-style: dashed !important;
}

.rounded {
    overflow: hidden;
}

$widths: [1, 5, 10, 15, 20, 33, 40, 60, 90];

@each $width in $widths {
    .w-#{$width} {
        @if $width == 33 {
            width: calc(100% / 3);
        }
        @else {
            width: #{$width} + '%';
        }
    }
}

@each $breakpoint, $width in $grid-breakpoints {
  @each $width in $widths {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$width} {
        @if $width == 33 {
          width: calc(100% / 3) + ' !important';
        }
        @else {
          width: #{$width} + '% !important';
        }
      }
    }
  }
}
