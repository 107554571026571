//
// tabs.scss
//

.nav-tabs,
.nav-pills {
    .nav-item {
        &.show {
            .nav-link {
                opacity: 1;
            }
        }
    }

    .nav-link {
        opacity: 0.4;
        color: inherit;
        font-weight: $font-weight-semibold;

        &.active {
            opacity: 1;
        }
    }
}

.nav-tabs {
    > li {
        &:not(:last-child) {
            > a {
                @extend .me-3;
            }
        }
    }
}

.nav-lg {
    --#{$prefix}nav-link-font-size: 1.125rem;
    --#{$prefix}nav-link-font-weight: 500;
}

.nav-pills {
    > a {
        color: var(--#{$prefix}gray-700);
        font-weight: $font-weight-semibold;
    }
}

.bg-nav-pills {
    background-color: $nav-pills-bg;
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
    border-bottom: 2px solid var(--#{$prefix}border-color);

    .nav-item {
        margin-bottom: -1px;
    }

    li {
        a {
            border: 0;
            padding: 0 0 0.694em;
        }
        a.active {
            border-bottom: 2px solid $primary;
        }
    }
}
