.job-aids {
    border: 1px solid #ccc;
}

.job-aids-star {
    width: 30px;
    padding: 10px 15px 10px 15px;
    text-align: center;
}

.job-aids-link {
    width: 400px;
    padding: 5px 5px 5px 10px;
    text-align: left;
    font-weight: bold;
}
