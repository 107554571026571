//
// tables.scss
//

//Table centered (Custom)
.table-centered {

    th,
    td {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    background-color: var(--#{$prefix}table-bg) !important;
    white-space: nowrap;
    font-size: var(--#{$prefix}table-font-size);

    &:last-child {
        margin-bottom: 0;
    }

    th {
        color: var(--#{$prefix}table-header-color);
    }

    td {
        vertical-align: middle;
    }

    .action-icon {
        color: var(--#{$prefix}gray-600);
        font-size: 1.2rem;
        padding: 0 3px;

        &:hover {
            color: var(--#{$prefix}gray-700);
        }
    }

    > :not(caption)>*>* {
        border-top-width: 1px !important;
        border-bottom-width: 0 !important;
    }

    &.table-bordered {
        thead {
            td, th {
                background-color: var(--#{$prefix}gray-100) !important;
                font-weight: 500;
            }
        }

        th {
            background-color: var(--#{$prefix}gray-100) !important;
        }

        tbody {
            border-top: $border-width solid;
            border-top-color: inherit;
        }

        &:not(caption)>*>* {
            border-bottom: 1px solid var(--#{$prefix}table-border-color) !important;
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
}

.table-dark {
    th {
        color: $white;
    }
}

// Tables fluid
.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}

.table-action {
    width: 1px;
    text-align: right;

    i {
        vertical-align: middle;
    }
}


html[data-theme="dark"] {
    .table-light {
        --#{$prefix}table-color: var(--#{$prefix}body-color);
        --#{$prefix}table-bg: var(--#{$prefix}light);
        --#{$prefix}table-border-color: #{$table-group-separator-color};
    }
}
