.dot {
    display: inline-flex;
    width: .875em;
    height: .875em;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: var(--#{$prefix}dark);

    &-sm {
        width: 0.5em;
        height: 0.5em;
    }

    &-lg {
        width: 1.5em;
        height: 1.55em;
    }
}

@each $color, $value in $theme-colors {
    .dot-#{$color} {
        background-color: #{$value};
    }
}
