.jonah-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;

    // .jonah-loader__overlay
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--#{$prefix}body-bg);
        opacity: .9;
    }

    // .jonah-loader__content
    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 20vw;
        width: 140px;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: var(--#{$prefix}border-radius-2xl);
        background-color: var(--#{$prefix}dark);
        overflow: hidden;

        [data-theme="dark"] & {
            background-color: var(--#{$prefix}white);
        }

        @include media-breakpoint-down(md) {
            border-radius: var(--#{$prefix}border-radius-xl);
        }
    }

    // .jonah-loader__image
    &__image {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        transform: scale(1.5) translateY(.5em);

        @include media-breakpoint-down(md) {
            transform: scale(1.5) translateY(.25em);
        }

        [data-theme="light"] & {
            &.dark {
                display: none;
            }
        }

        [data-theme="dark"] & {
            &.light {
                display: none;
            }
        }
    }
}
