// Lighten badge
@mixin badge-variant-light($bg, $color) {
    @if $color == 'dark' {
        --#{$prefix}badge-color: var(--#{$prefix}light) !important;
        background-color: var(--#{$prefix}dark);
    }
    @else {
        --#{$prefix}badge-color: var(--#{$prefix}dark) !important;
        background-color: rgba($bg, 0.5);
    }
}

// Outline badge

@mixin badge-variant-outline($bg) {
    --#{$prefix}badge-color: #{$bg};
    border: 1px solid $bg;
    background-color: transparent;
}
