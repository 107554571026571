//
// switch.scss
//

//Custom switch

input[data-switch] {
    display: none;

    + label {
        width: 56px;
        height: 24px;
        background-color: var(--#{$prefix}form-switch-bg);
        background-image: none;
        border-radius: 2rem;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        position: relative;
        color: var(--#{$prefix}gray-400);
        transition: all 0.1s ease-in-out;

        &:before {
            color: var(--#{$prefix}body-color);
            content: attr(data-off-label);
            display: block;
            font-family: inherit;
            font-weight: $font-weight-normal;
            font-size: 0.75rem;
            line-height: 24px;
            position: absolute;
            right: 3px;
            margin: 0 0.21667rem;
            top: 0;
            text-align: center;
            min-width: 1.66667rem;
            overflow: hidden;
            transition: all 0.1s ease-in-out;
        }

        &:after {
            content: "";
            position: absolute;
            left: 4px;
            background-color: currentColor;
            box-shadow: none;
            border-radius: 2rem;
            height: 18px;
            width: 18px;
            top: 3px;
            transition: all 0.1s ease-in-out;
        }
    }

    &:checked {
        + label {
            background-color: var(--#{$prefix}success);
            color: var(--#{$prefix}body-color-inv);

            &:before {
                color: var(--#{$prefix}body-color-inv);
                content: attr(data-on-label);
                right: auto;
                left: 4px;
            }

            &:after {
                left: 34px;
            }
        }
    }

    @each $color, $value in $theme-colors {
        &[data-switch="#{$color}"] {
            &:checked {
                + label {
                    background-color: var(--#{$prefix}#{$color});
                }
            }
        }
    }
}

input[data-switch="dark"] {
    &:checked + label {
        background-color: var(--#{$prefix}dark);
        color: var(--#{$prefix}light);

        &::before {
            color: var(--#{$prefix}light);
        }
    }
}

input[data-switch="bool"] {
    +label {
        background-color: var(--#{$prefix}danger);
        color: var(--#{$prefix}body-color-inv);

        &::before {
            color: var(--#{$prefix}body-color-inv);
        }
    }

    &:checked + label {
        background-color: var(--#{$prefix}success);
        color: var(--#{$prefix}body-color-inv);
    }
}

input:disabled {
    +label {
        opacity: 0.5;
        cursor: default;
    }
}
