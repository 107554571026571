.copy {
    position: relative;
    border-radius: var(--#{$prefix}border-radius);

    // .copy__btn
    &__btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    // .copy__content
    &__content {}
}
