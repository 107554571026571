//
// buttons.scss
//

.btn {
  --#{$prefix}btn-color: #{$body-color};

  &:not([class*="btn-outline-"]) {
    --#{$prefix}btn-hover-color: var(--#{$prefix}body-color);
    --#{$prefix}btn-hover-bg: transparent;
  }

  &[class*="btn-outline-"] {
    --#{$prefix}btn-color: var(--#{$prefix}body-color);
    --#{$prefix}btn-hover-color: var(--#{$prefix}dark);
  }

  &[class*="btn-outline-dark"] {
    --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  }

  &-link {
    --#{$prefix}btn-color: var(--#{$prefix}primary-alt);
    --#{$prefix}btn-hover-color: var(--#{$prefix}body-color);
  }

  .mdi {
    &:before {
      line-height: initial;
    }
  }

  .spinner-grow, .spinner-border {
    vertical-align: middle;
  }

  &.disabled,
  &:disabled {
    --#{$prefix}btn-disabled-color: #{$black};
    box-shadow: none;
    border-color: var(--#{$prefix}btn-disabled-border-color, var(--#{$prefix}btn-border-color));

    &.btn-dark {
      --#{$prefix}btn-disabled-color: #{$white};
    }
  }

  @include media-breakpoint-down(sm) {
    --#{$prefix}btn-font-size: 0.8rem;
  }
}

.btn-tiny {
  appearance: none;
  margin-top: 5px;
  border-radius: $border-radius-sm;
  border: 1px solid var(--#{$prefix}border-color);
  background: none;
  padding: .05em .5em;
  color: inherit;
  font-size: 10px;
}

.btn-quicklinks {
  border: 1px solid var(--#{$prefix}border-color);
  padding: 0.167em 0.667em;
  color: inherit;
  font-size: 12px;
}

// Button Shadows
@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    box-shadow: $btn-box-shadow rgba(var(--#{$prefix}#{$color}-rgb), 0.5);
  }
}

// Button Soft
@each $color,
$value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

// Button Light and Dark
.btn-light {
  // --#{$prefix}btn-color: var(--#{$prefix}dark);
  // --#{$prefix}btn-bg: var(--#{$prefix}light);
  // --#{$prefix}btn-border-color: var(--#{$prefix}light);
  // --#{$prefix}btn-hover-color: var(--#{$prefix}body-color);
  // --#{$prefix}btn-hover-bg: transparent;
  // --#{$prefix}btn-hover-border-color: var(--#{$prefix}light);
  // --#{$prefix}btn-disabled-color: var(--#{$prefix}dark);
  // --#{$prefix}btn-disabled-bg: var(--#{$prefix}light);
  // --#{$prefix}btn-disabled-border-color: var(--#{$prefix}light);
}

.btn-dark {
  --#{$prefix}btn-color: var(--#{$prefix}light);
  --#{$prefix}btn-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-disabled-color: var(--#{$prefix}light);
  --#{$prefix}btn-disabled-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-disabled-border-color: var(--#{$prefix}dark);
}

.btn-outline-dark {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-active-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-disabled-color: var(--#{$prefix}light);
  --#{$prefix}btn-disabled-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-disabled-border-color: var(--#{$prefix}dark);
}

.btn-outline-light {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-color: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}light);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-color: var(--#{$prefix}dark);
  --#{$prefix}btn-active-bg: var(--#{$prefix}light);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}light);
  --#{$prefix}btn-disabled-color: var(--#{$prefix}dark);
  --#{$prefix}btn-disabled-bg: var(--#{$prefix}light);
  --#{$prefix}btn-disabled-border-color: var(--#{$prefix}light);
}

.btn-soft-dark {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: var(--#{$prefix}light);
  --#{$prefix}btn-border-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-active-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
}

// Dark Mode
html[data-theme="dark"] {
  .btn-light {
    --#{$prefix}btn-active-color: var(--#{$prefix}dark);
    --#{$prefix}btn-active-bg: var(--#{$prefix}gray-300);
    --#{$prefix}btn-active-border-color: var(--#{$prefix}gray-300);
  }

  .btn-dark {
    --#{$prefix}btn-active-color: var(--#{$prefix}light);
    --#{$prefix}btn-active-bg: var(--#{$prefix}gray-700);
    --#{$prefix}btn-active-border-color: var(--#{$prefix}gray-700);
  }
}
