//
// card.scss
//
.card {
    margin-bottom: $grid-gutter-width;

    .card-drop {
        font-size: 20px;
        color: inherit;
    }

    .card-widgets {
        float: right;
        height: 16px;
        > a {
            color: inherit;
            font-size: 18px;
            display: inline-block;
            line-height: 1;

            &.collapsed {
                i {
                    &:before {
                        content: "\F0415";
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        --#{$prefix}card-spacer-y: 10px;
        --#{$prefix}card-spacer-x: 10px;
    }
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 $grid-gutter-width;
    border-bottom: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color)!important;
    padding: var(--#{$prefix}card-spacer-y) 0 calc(var(--#{$prefix}card-spacer-y) / 2);

    &[class*="bg-"] {
        margin: 0;
        border: 0;
        padding: $grid-gutter-width;
    }

    > * {
        display: inline-flex;
    }
}

.card-footer {
    padding: calc(var(--#{$prefix}card-spacer-y) / 2) var(--#{$prefix}card-spacer-x);
}

.header-title,
.card-title {
    margin-top: 0 !important;
    letter-spacing: 0.02em;
    font-size: 18px;
    font-weight: 500;
}

.card-title {
    margin: 0 !important;
}

.card-subtitle {
  margin-top: calc(var(--#{$prefix}card-title-spacer-y) * 0.2);
  margin-bottom: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: var(--#{$prefix}gray-100);
    opacity: 0.8;
    cursor: progress;

    .card-portlets-loader {
        background-color: var(--#{$prefix}gray-900);
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -($grid-gutter-width * 0.5);
        margin-top: -($grid-gutter-width * 0.5);
    }
}

.card-img-start,
.card-img-end {
    width: 100%;
}

.card-img-start {
    @include border-start-radius(var(--#{$prefix}card-inner-border-radius));
}

.card-img-end {
    @include border-end-radius(var(--#{$prefix}card-inner-border-radius));
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Pricing card

.card-pricing {
    position: relative;

    .card-pricing-plan-name {
        padding-bottom: 20px;
    }

    .card-pricing-icon {
        font-size: 22px;
        box-shadow: $box-shadow;
        height: 60px;
        display: inline-block;
        width: 60px;
        line-height: 56px;
        border-radius: 50%;
    }

    .card-pricing-price {
        padding: 30px 0 0;

        span {
            font-size: 40%;
            color: var(--#{$prefix}gray-600);
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .card-pricing-features {
        color: var(--#{$prefix}gray-600);
        list-style: none;
        margin: 0;
        padding: 20px 0 0 0;

        li {
            padding: 15px;
        }
    }
}

.card-pricing-recommended {
    @include media-breakpoint-up(lg) {
        margin-top: -1.9375rem;
    }

    .card-pricing-plan-tag {
        background-color: rgba($danger, 0.2);
        color: $danger;
        padding: 5px 0;
        font-weight: 700;
        border-radius: 0.25rem 0.25rem 0 0;
        margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    }
}

// Custom card height

.card-h-100 {
    height: calc(100% - #{$grid-gutter-width});
}
