//
// horizontal-nav.scss
//

.topnav {
    position: fixed;
    width: 100%;
    margin-top: var(--#{$prefix}topbar-height);
    background: $bg-topnav;
    z-index: 1000;

    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            font-size: 0.9375rem;
            position: relative;
            padding: 1rem 1.3rem;
        }
    }

    .nav-item {
        &.active {
            >a {
                color: $menu-item-active;
            }
        }
    }

    .navbar-dark {
        .dropdown {

            &.active,
            &:active {
                >.nav-link {
                    color: $white;
                }
            }
        }
    }

    &.topnav-light {
        background: $bg-topbar;

        .navbar-light {
            .dropdown {

                &.active,
                &:active {
                    >.nav-link {
                        color: $primary;
                    }
                }
            }
        }

    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: 0.4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all 0.3s ease-out;
        width: 0.4em;
    }
}

// Layout -topnav
html[data-layout="topnav"] {
    .topnav-navbar {
        padding: 0;
        margin: 0;
        min-height: var(--#{$prefix}topbar-height);
        position: fixed;
        left: $grid-gutter-width !important;
        z-index: 1002;
        width: calc(100% - (#{$grid-gutter-width} * 2));
        border: 0;
        border-radius: 0 0 $border-radius $border-radius;

        .logo-topbar {
            float: none;
            display: inline-block !important;
            vertical-align: middle;
            padding: 0 $grid-gutter-width 0 0 !important;
        }

        .navbar-toggle {
            display: block;
            position: relative;
            cursor: pointer;
            margin: 0;
            padding: 17px $grid-gutter-width;
            background-color: transparent;
            color: var(--#{$prefix}gray-900);
            border: none;

            .lines {
                width: 25px;
                display: block;
                position: relative;
                height: 16px;
                transition: all 0.5s ease;
            }

            span {
                height: 2px;
                width: 100%;
                background-color: var(--#{$prefix}body-color);
                display: block;
                margin-bottom: 5px;
                transition: transform 0.5s ease;
            }

            &.open {
                span {
                    position: absolute;

                    &:first-child {
                        top: 7px;
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        visibility: hidden;
                    }

                    &:last-child {
                        width: 100%;
                        top: 7px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        .app-search {
            float: left;
        }

        .container-fluid {
            padding-right: 0;
        }
    }

    .content-page {
        margin-left: 0 !important;
        padding: calc(var(--#{$prefix}topbar-height) + 55px) 0 var(--#{$prefix}topbar-height);
        // padding: 0 0 60px 0;

    }

    .container-fluid {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;

        .logo-topbar {
            display: block;
        }
    }

    #sidebar-color,
    #sidebar-size,
    #layout-detached,
    #sidebar-user {
        display: none;
    }
}


@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
                min-width: calc(#{$dropdown-min-width} + 1.5rem);
                @include font-size(calc(#{$dropdown-font-size} - 0.01rem));

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        display: none;
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block;
        }
    }

    .dropdown.active>a.dropdown-item {
        color: $dropdown-link-active-color;
        @include gradient-bg($dropdown-link-active-bg);
    }
}

@include media-breakpoint-up(lg) {
    .navbar-toggle {
        display: none !important;
    }
}

@include media-breakpoint-down(lg) {
    .topnav {
        max-height: 360px;
        overflow-y: auto;

        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.3rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;
            }
        }

        .navbar-dark {
            .dropdown {
                .dropdown-item {
                    color: rgba($white, 0.5);

                    &.active,
                    &:active {
                        color: $white;
                    }
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}

html[data-layout="topnav"] {
    .content-page {
        padding-top: var(--#{$prefix}topbar-height);
    }
}

@include media-breakpoint-up(xxl) {
    html[data-layout="topnav"] {
        &[data-layout-mode="boxed"] {
            .navbar-custom {
                margin: 0;
                left: calc(calc(100% - #{$boxed-layout-width}) * 0.5) !important;
                max-width: $boxed-layout-width;
            }

            .topnav {
                width: $boxed-layout-width;
            }

        }
    }

}
