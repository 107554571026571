//
// page-head.scss
//

.page-title-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: #{$grid-gutter-width * 1.5} $grid-gutter-width $grid-gutter-width;

    .page-title {
        display: inline-flex;
        order: 1;
        font-size: 30px;
        margin: 0;
        color: $page-title-color;
        font-weight: $font-weight-semibold;
    }

    .page-title-right {
        display: inline-flex;
        order: 2;
        padding-left: $grid-gutter-width;
    }

    .breadcrumb {
        @include text-truncate;
        display: block;
        --#{$prefix}breadcrumb-padding-y: 0;
        padding-top: 8px;

        li {
            display: inline-block;
        }
    }
}

.page-title-box-sm {
    .page-title {
        line-height: 1 !important;
        margin-bottom: 25px;
    }

    .page-title-right {
        float: right;
        margin-top: 0;
    }

    .breadcrumb {
        padding-top: 0;
        margin-top: -3px !important;
    }
}

.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(md) {
    .page-title-box {
        padding: math.div($grid-gutter-width, 2) 0;

        .page-title {
            display: block;
            font-size: 20px;
        }

        .breadcrumb {
            display: none;
        }

        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
