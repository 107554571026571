// background per breakpoint
@each $breakpoint, $width in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
        @each $color, $value in $theme-colors {
            .bg-#{$breakpoint}-#{$color} {
                background-color: #{$value} !important;
            }
        }
        .bg-#{$breakpoint}-transparent {
            background-color: transparent !important;
        }
    }
}

// z-index map
@each $class, $value in $zindex-levels {
    .z-#{$class} {
        z-index: #{$value};
    }
}
