@import 'prismjs/theme-default';

// Toolbar
div.code-toolbar {
    > .toolbar {
        display: flex;
        padding-top: 4px;
        padding-right: 2px;
        top: 0;
        opacity: 1;

        > .toolbar-item {
            display: inline-flex;

            > a,
            > button,
            > span {
                border-radius: $border-radius-sm;
                background-color: var(--#{$prefix}card-bg);
                box-shadow: none;
                color: var(--#{$prefix}gray-500);
            }
        }
    }
}

.pre-expand {
    display: block;
    margin-bottom: .5em;
    width: 100%;
    border-radius: 0 0 var(--code-border-radius) var(--code-border-radius);
    border:0;
    background: var(--#{$prefix}gray-200);
    color: inherit;
    line-height: 1;
    font-size: 20px;

    &:hover,
    &:focus {
        background-color: var(--#{$prefix}gray-400);
    }

    [data-theme="dark"] & {
        background: var(--#{$prefix}gray-300);

        &:hover,
        &:focus {
            background-color: var(--#{$prefix}gray-500);
        }
    }
}
