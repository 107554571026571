//
// forms.scss
//

// Form-control light

.form-control-light {
    border-color: var(--#{$prefix}gray-200) !important;
}

// Form elements (Color and Range)

input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}

.form-control:disabled {
    color: $gray-800;
}

// Custom select

.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

// Show/Hide Password
.password-eye {
    cursor: pointer;

    &:before {
        font-family: "Material Design Icons";
        content: "\F06D0";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\F06D1";
        }
    }
}

input[type=search] {
    background-color: var(--#{$prefix}search-bg);
    border-color: var(--#{$prefix}search-border-color);
    color: var(--#{$prefix}search-color);

    &::placeholder {
        color: var(--#{$prefix}search-color);
    }

    &::-webkit-search-cancel-button {
        appearance: none;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 0;
        padding: 2px;
        color: #000;
        background:
            linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%, var(--#{$prefix}search-color) 45%, var(--#{$prefix}search-color) 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
            linear-gradient(135deg, transparent 0%,transparent 43%, var(--#{$prefix}search-color) 45%, var(--#{$prefix}search-color) 55%,transparent 57%,transparent 100%);
    }

    &:focus {
        background-color: var(--#{$prefix}search-bg);
        color: var(--#{$prefix}search-color);
    }
}

.checklist {
    .form-check {
        padding-left: 1.5rem;

        .form-check-input {
            margin-left: -1.5rem;

            + label {
                display: inline;
            }

            &:checked {
                + label {
                    color: var(--#{$prefix}success);
                    text-decoration: line-through;
                }
            }
        }

        &.fail {
            .form-check-input:checked {
                border-color: var(--#{$prefix}danger);
                background-color: var(--#{$prefix}danger);
                background-image: escape-svg($form-check-input-checked-bg-image-fail);

                + label {
                    color: var(--#{$prefix}danger);
                    text-decoration: none;
                }
            }
        }

        &.not-applicable {
            .form-check-input:checked {
                border-color: var(--#{$prefix}gray-400);
                background-color: var(--#{$prefix}gray-400);
                background-image: escape-svg($form-check-input-checked-bg-image-na);

                + label {
                    color: var(--#{$prefix}gray-400);
                }
            }
        }
    }
}


.error{
    color: var(--#{$prefix}danger);
    font-size: .75rem;
    font-weight: normal;
    border-color: var(--#{$prefix}danger);

    &:focus {
        border-color: var(--#{$prefix}danger);
    }
}
