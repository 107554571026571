@import 'dot';
@import 'job-aids';
@import 'notes';
@import 'switch';
@import 'tables';
@import 'yuri';

.max-width {
    margin-left: 0;
    max-width: 1600px;
}

.text-decoration-line-through a {
    color: inherit;
}

// ----------------------------------------------------------------------------
//  Name Classes used for truncating
// ----------------------------------------------------------------------------
.jonah-truncate {
    @include text-truncate();
    display: inline-block;
    max-width: 100%;
    width: 100%;
}

.jonah-cell-truncate {
    @include text-truncate();
    max-width: 150px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    border: 0;
    box-shadow: none;
}

.fc .fc-timegrid-col.fc-day-today {
    background-color: var(--#{$prefix}gray-100);
}

.fc-v-event .fc-event-main {
    color: inherit;
}

.content-block {
    position: relative;
    display: block;
    padding: 1em 1.3em;
    margin: 0.5em 0;
    background: var(--code-bg);
    color: var(--code-color);
    border-radius: var(--code-border-radius);
    font-size: 1em;
    text-align: left;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    overflow: auto;

    a:not(.btn) {
        text-decoration: underline;
    }

    // .content-block__expand
    &__expand {
        display: block;
        width: 100%;
        border-radius: 0 0 var(--code-border-radius) var(--code-border-radius);
        border:0;
        background: var(--#{$prefix}gray-200);
        color: inherit;
        line-height: 1;
        font-size: 20px;

        &:hover,
        &:focus {
            background-color: var(--#{$prefix}gray-400);
        }

        [data-theme="dark"] & {
            background: var(--#{$prefix}gray-300);

            &:hover,
            &:focus {
                background-color: var(--#{$prefix}gray-500);
            }
        }
    }

    .code-toolbar {
        &:not(:last-child) {
            margin-bottom: 2em;
        }

        > .toolbar {
            > .toolbar-item {
                a, button, span {
                    background-color: var(--#{$prefix}gray-100);
                }
            }
        }
    }

    pre[class*=language-],
    code {
        --code-bg: var(--#{$prefix}white);

        [data-theme="dark"] & {
            --code-bg: var(--#{$prefix}black);
        }
    }

    &.content-expanded {
        overflow: visible;
    }
}

.description {
    p {
        a:not(.btn) {
            text-decoration: underline;
        }
    }
}
